import React, { useState, useEffect } from "react";
import background from "../assets/images/background.jpeg";

export default function LoadingScreen() {
  const [displayLoading, setDisplayLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setDisplayLoading(false);
    }, 5000);
    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <div
      style={{ display: displayLoading ? "block" : "none" }}
      className="loading-page"
    >
      <img src={background} alt="Chat App Background" />
      <div className="loading-page-center">
        <div className="loading-title">
          <h1>
            <i className="icons fi fi-rr-comments"></i>Wazapp
          </h1>
        </div>
        <div className="loading-container">
          <div className="box box1"></div>
          <div className="box box2"></div>
          <div className="box box3"></div>
          <div className="box box4"></div>
          <div className="box box5"></div>
        </div>
      </div>
      <div className="loading-line"></div>
    </div>
  );
}

/* SET COOKIES */

// function setCookies() {
// const [cookies, setCookies] = useState()

//   declineCookies() {

//   }
//   acceptCookies() {

//   }

//   return (
//     <div className="cookies-modul">
//       <p></p>
//       <div className="cookies-btn">
//         <button onClick={declineCookies}>Decline</button>
//         <button onClick={acceptCookies}>Accept Cookies</button>
//       </div>
//     </div>
//   );
// }
