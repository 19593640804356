import React, { useState, useEffect } from "react";
import noProfile from "../assets/images/No profile.jpg";
import EmojiPicker from "emoji-picker-react";
import sound from "../assets/audio/Message Sent.mp3";

export default function MessageInput() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  let audio = new Audio(sound);

  /* GET RANDOM USER WHEN MESSAGE IS SENT */
  useEffect(() => {
    updateCurrentUser();
  }, []);

  const updateCurrentUser = () => {
    const users = [
      "Gerrard Butler",
      "Megan Fox",
      "Leonardo DiCaprio",
      "Tom Hanks",
      "Johnny Depp",
      "Angelina Jolie",
      "Jason Statham",
      "Brad Pitt",
      "Tom Cruise",
      "Morgan Freeman",
      "Bruce Willis",
      "Vin Diesel",
      "Scarlett Johansson",
      "Cameron Diaz",
    ];

    const randomUser = users[Math.floor(Math.random() * users.length)];
    setCurrentUser(randomUser);
  };

  /* HOUR */

  const options = {
    hour: "numeric",
    minute: "numeric",
  };

  /* SEND MESSAGE ON CLICK AND KEYPRESS */
  const sendMessage = () => {
    if (inputValue.trim() !== "") {
      const newMessage = {
        text: inputValue,
        time: new Date(),
        user: currentUser,
        className: "sended-message",
      };
      setMessages([...messages, newMessage]);
      setInputValue("");
      audio.play();
    }
  };

  const sendOnKeyPress = (event) => {
    if (event.key === "Enter") {
      if (inputValue.trim() !== "") {
        sendMessage();
        audio.play();
      }
    }
  };

  /* SHOW EMOJIS ON CLICK */
  const emojiButton = () => {
    setShowEmoji(!showEmoji);
  };
  const showEmojiBox = (emojiObject) => {
    const emoji = emojiObject.emoji;
    setInputValue((prevValue) => prevValue + emoji);
    emojiButton();
  };

  return (
    <div className="chat-app">
      {/* *****MESSAGES LISTS***** */}
      <div className="messages">
        <ul className="list">
          {messages.map((message) => (
            <li key={crypto.randomUUID} className="textMessage">
              <div className="message-box">
                <h2 className="user">{message.user}</h2>
                <img
                  className="message-profile"
                  src={noProfile}
                  alt="No profile"
                ></img>
              </div>
              <p className="message">
                {message.text}
                <span className="date">
                  {" "}
                  {message.time.toLocaleTimeString("hr-HR", options)}
                </span>
              </p>
            </li>
          ))}
        </ul>
      </div>
      {/* *****SEND MESSAGE INPUT FIELD***** */}
      <div className="chat-box">
        <button className="chat-btn" onClick={emojiButton}>
          <i className="fi fi-rr-smile"></i>
        </button>
        <input
          type="text"
          id="inputField"
          placeholder="Send Message..."
          required
          value={inputValue}
          onKeyDown={sendOnKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button className="chat-btn" onClick={sendMessage}>
          <i className="fi fi-rr-paper-plane"></i>
        </button>
      </div>
      {/* ***** EMOJI BOX***** */}
      {showEmoji && (
        <div className="emoji-picker-container">
          <EmojiPicker onEmojiClick={showEmojiBox} />
        </div>
      )}
      {/*  */}
    </div>
  );
}
